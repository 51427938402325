.icon-edit {
  background-color: #4B0082;
  /* Background color */
  color: white;
  /* Icon color */
}

.userEdit {
  font-size: 35px;
  padding: 5px;
  border-radius: 5px;
  margin-right: 15px;
  cursor: pointer;
}

.table-responsive {
  overflow-y: auto;
  max-height: 400px;
  /* Adjust the height as per your requirement */
}

/* .table thead {
  position: sticky;
  top: 0;
  z-index: 100;

  
}

*/
td {
  vertical-align: middle !important;
  font-size: 18px !important;
}

th {
  background-color: #4c0082f7 !important;
  color: #FFFFFF !important;
  font-family: 'Times New Roman', Times, serif !important;
  font-size: 20px !important;
  font-weight: bolder !important;
}

.table th,
.table td {
  white-space: nowrap;
  padding: 8px;
}

/* .table-scroll {
  height: 448px;
  overflow-y: scroll;
  max-height: 450px;
} */